<template>
  <main>
    <div class="container">
      <ThisComponent text-enter-phone-number="" textEnterCode="Введи код из SMS">
        <h1>Авторизация</h1>
        <template #enter-phone>
          <div>
            <p>Здесь ты можешь забрать свой подарок.<br>Введи номер телефона, к которому привязан твой личный кабинет в приложении My OMODA.</p>
          </div>
        </template>
        <template #enter-code>
        </template>
      </ThisComponent>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AuthorizationSmsCode'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--auth')
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style lang="css" scoped>
  h1 {
    text-align: center;
    margin-bottom: 14px;
    line-height: 120%;
    letter-spacing: -.01em;
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 400;
  }

  h2 {
    text-align: center;
    color: var(--gray);
    max-width: 285px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }

  .form {
    margin-bottom: 20px;
    max-width: 450px;
    background-color: #f1f4f5;
    border-radius: 30px;
    justify-content: center;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 auto;
  }

  @media (min-width: 640px) {
    .form {
      max-width: 460px;
      padding: 60px 70px;
    }
  }

  ::v-deep .form div {
    width: 100%;
  }

  ::v-deep .form .step__fields {
    gap: 14px;
    flex-direction: column !important;
  }

  ::v-deep .form input {
    border: var(--form-border);
  }
</style>
